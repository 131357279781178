<template>
  <div class="px-4 lg:px-8 max-w-screen-lg mx-auto">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "NeeraContainer",
};
</script>
<style scoped></style>
